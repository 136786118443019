/*#root{*/
/*    margin-top: 87px;*/
/*}*/

.work-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: black;
    z-index: 3;
    padding: 5vh 0;
}
.work-header .burger{
    display: none;
}
.work-header .nav-logo-top{
    margin-top: 0;
}
.work-header-items {
    /*margin-top: 2%;*/
    margin-right: 7%;
    display: flex;

}

.work-header-item,
.work-header-item-separator {
    color: #E6E6E8;
    margin-left: 1vw;

    text-transform: uppercase;

    font-size: 1.2em;
    cursor: pointer;
}

.work-header-item:hover,
.work-header-item-selected {
    color: #aaa;
    transform: scale(1.05);
    transition: all .5s ease-in-out
}

.work-content {
    /*margin: 0px 0 3% 0;*/
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /*grid-template-rows: repeat(3, 1fr);*/
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.work-content-item {
    position: relative;
    cursor: pointer;
}

.work-content-item,
.work-content-item img {
    width: 100%;
    height: 100%;
}

.play-btn.show-on-hover{
    opacity: 1;
}
.play-btn {
    display: block;
    position: absolute;
    margin: auto auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 35% !important;
    height: auto !important;
    opacity: 0;
    transition: all .4s;
}

.work-content-item p {
    display: none;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 25%;
}

.show-on-hover {
    display: block;
}
.modal-video{
    position: fixed;
    top: 87px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 87px);
    z-index: 4;
}


@keyframes fadeInModalVideo {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-video .modal-container-fade{
    animation-name: fadeInModalVideo;
}
.modal-video .project-title{
    font-size: 2.3em;
    margin: 0 0 10px 0;
    text-align: center;
}
.modal-video .project-desc{
    font-size: 1.5em;
    margin: 0 0 15px 0;
    color: #aaa;
}
.modal-video .iframe-wrapper{
    width: 50vw;
    aspect-ratio: 16/9;
}
.modal-video .iframe-wrapper iframe{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.work-footer {
    color: #E6E6E8;
}
.work-footer .copyright-text{
    font-family: 'Arial', sans-serif;
}


.thumb-vertical {
    position: relative;
    display: block;
    width: 3px;
    cursor: pointer;
    border-radius: inherit;
    background-color: white!important;
}

.track-vertical {
    position: absolute;
    width: 2px!important;
    display: block!important;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
}

footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #000201;
    text-align: center;
    padding: 1rem;
}

.hidden-menu{
    display: none;
}
@media screen and (max-width: 768px) {
    .nav-logo img{
        width: 20px;
        height: 20px;
        margin-top: -3px;
    }
    .work-header{
        padding: 30px 20px 15px 20px;
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 10px 0;
        box-sizing: border-box;
    }
    .work-header .nav-logo-top{
        z-index: 1;
        margin-left: 0;
    }
    .work-header-items{
        grid-column: 1/-1;
        grid-row: 2/3;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 10px;
        margin: 0;
    }

    .work-header-items .work-header-item{
        font-size: 10px;
        margin: 0;
    }
    .work-header-items .work-header-item-separator{
        display: none;
    }
    .work-content{
        grid-template-columns: 1fr;
    }
    .work-header .burger{
        display: block;
        width: 31px;
        height: 30px;
        position: relative;
        z-index: 2;
        margin: 0 0 0 auto;
    }
    .work-header .burger span{
        display: block;
        width: 100%;
        height: 2px;
        background: white;
        position: absolute;
        left: 0;
        transition: all .3s;
    }
    .work-header .burger span:nth-child(1){
        top: 6px;
    }
    .work-header .burger span:nth-child(2){
        top: 50%;
        transform: translateY(-50%);

    }
    .work-header .burger span:nth-child(3){
        bottom: 6px;
    }

    .work-header .burger.active span:nth-child(1){
        transform: rotate(45deg);
        top: 12px;
    }
    .work-header .burger.active span:nth-child(2){
        opacity: 0;
    }
    .work-header .burger.active span:nth-child(3){
        transform: rotate(-45deg);
        top: 12px;
        bottom: auto;
    }

    .modal-video{
        /*top: 70px;*/
        top: 100px;
        height: calc(var(--vh, 1vh) * 100 - 100px);
    }
    .modal-video .modal-content{
        width: calc(100% - 90px);
        margin: -100px 0 0 0;
        box-sizing: border-box;
    }
    .modal-video .modal-header{
        display: none;
    }
    .modal-video .iframe-wrapper{
        width: 100%;
    }

    .modal-video .project-title{
        font-size: 1.5em;
        margin-bottom: 5px;
    }
    .modal-video .project-desc{
        font-size: 1em;
        margin-bottom: 10px;
    }
    .work-content{
        box-sizing: border-box;
        padding: 100px 45px 10px 45px;
        margin-bottom: 0;
    }

    footer{
        transform-origin: left;
        transform: rotate(-90deg);
        font-size: 12px;
        display: block;
        width: auto;
        padding: 15px 40px;
        left: 22px;
        bottom: -22px;
        z-index: 99;
    }

     /*Hidden menu*/
    .hidden-menu{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(	0, 1, 2, 0.7);
        z-index: 5;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        /*opacity: 0;*/
        /*pointer-events: none;*/
        display: none;
    }
    .hidden-menu.active{
        display: flex;
    }
    .hidden-menu .hidden-menu__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*gap: 40px 0;*/
    }
    .hidden-menu .hidden-menu__content p{
        color: white;
        text-align: center;
        font-size: 1.5em;
        margin: 0;
        padding: 20px 0;
    }

    @keyframes fadeInModal {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .modal-contacts .modal-content .mail{
        font-size: 1.7em;
    }
    .modal-contacts .modal-content ul a{
        font-size: 1em;
    }
}
