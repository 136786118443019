@font-face {
font-family: "Arial";
src: local("Arial"),
url("./fonts/arial/arial-regular.ttf") format("truetype");
font-weight: 400;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000102;
  font-family: 'Electrolize', sans-serif;
}
#root{
  overflow: hidden;
}
.work-content {
  margin: 0px 0 3% 0;
  padding-top: calc(10vh + 40px);
}
@media screen and (max-width: 768px) {
  .work-content {
    padding-top: 70px;
  }
}
