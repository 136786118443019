@media (prefers-reduced-motion:no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin 20s linear infinite;
        animation: App-logo-spin 20s linear infinite
    }
}

@-webkit-keyframes App-logo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes App-logo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.page-container {
    grid-column-gap: 0;
    grid-row-gap: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 100vh;
    width: 100vw
}

.nav-item {
    color: #E6E6E8;
    display: flex;
    z-index: 2;
}

.nav-logo {
    display: flex;
    align-items: center;
    transition: all .2s linear;
    /*position: relative;*/
}
.nav-logo:hover{
    transform: scale(1.1);
}

.hidden {
    visibility: hidden;
}

.nav-logo-top {
    z-index: 101;
}

.nav-logo img {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-top: -4px;
}



.nav-item-label {
    font-size: 2em;
    font-weight: 400;
    vertical-align: baseline;
    cursor: pointer;
}

.nav-item-label:hover {
    color: #fff;
}

.nav-subitems li:hover {
    color: #fff;
}

/*.nav-item-hovered .nav-item-label {*/
/*    color: #fff;*/
/*    font-size: 1.8em;*/
/*    !* transform: scale(1.25); *!*/
/*    transition: all .5s ease-in-out*/
/*}*/

.nav-item-faded .nav-item-label {
    color: #E6E6E8;
    font-size: 2em;
    transition: all .5s ease-in-out
}

@-webkit-keyframes NavItemShowDescription {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes NavItemShowDescription {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}
.footer{
    /*margin-left: 7.5vw;*/
    /*margin-top: 5vh;*/
    /*margin-right: 7.5vw;*/
    /*margin-bottom: 10vh;*/
    margin: 5vh 7.5vw 10vh 7.5vw;
    display: flex;
    justify-content: space-between;
    grid-column: 1/-1;
    /* margin: 0; */
    align-items: end;
    box-sizing: border-box;
}

.nav-item-top-left {
    align-items: flex-start;
    margin-right: 15%
}

.nav-item-top-left,
.nav-item-top-right {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 7.5vw;
    margin-top: 5vh;
}

.nav-item-top-right {
    align-items: flex-end;
    margin-right: 7.5vw;
}

.nav-item-bottom-left {
    align-items: flex-start
}

.nav-item-bottom-left,
.nav-item-bottom-right {
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 12vh;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
}

.nav-item-bottom-right {
    align-items: flex-end
}
.nav-item-bottom-left .nav-work-item,
.nav-item-bottom-right .nav-work-item{
    min-height: 80px;
    display: flex;
    justify-content: center;
}

.nav-subitems {
    list-style-type: none;
    padding: 0;
    cursor: pointer;
}

.nav-work-item {
    display: flex;
    flex-direction: column;
}
.nav-item-top-right .nav-work-item{
    position: relative;
}
.nav-item-top-right .nav-item-label{
    position: relative;
    font-size: 2em;
    padding-left: 150px;
}
.nav-item-top-right .nav-item-label.nav-item-label__mobile{
    display: none;
}
.nav-item-top-right .nav-item-label:before{
    content: '';
    position: absolute;
    bottom: 6px;
    left: 0;
    height: 2px;
    width: 140px;
    background-color: #E6E6E8
}
.nav-item-top-right .nav-subitems{
    margin: 0;
    position: absolute;
    left: 0px;
    top: 38px;
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    width: 100%;
    /*padding-top: 40px;*/
}
.nav-item-top-right .nav-subitems li{
    font-size: 1.5em;
    line-height: 120%;
    white-space: nowrap;
    overflow: hidden;
}
.nav-item-top-right .nav-subitems li p{
    margin: 0;
    padding: 0;
    transform: translateY(-110%);
    transition: all .4s;
    opacity: 0;
}
.nav-subitems.active li p{
    opacity: 1;
    transform: translateY(0px);
}
.nav-subitems.active li p:hover{
    color: white;
}


.center-logo-container {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1
}

.center-logo {
    height: 55vh;
    width: 55vw;
}

.center-logo-container {
    height: 100vh;
    width: 100vw;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.9;
    }
}
@keyframes fadeInModal {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.9;
    }
}

.modal-container {
    z-index: 2;
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-header {
    position: absolute;
    top: 0;
    right: 0;
}

.modal-header button {
    margin-top: 5vh;
    margin-right: 7.5vw;

    border: none;
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    font-size: 1.6em;
    cursor: pointer;
}

.modal-container-fade {
    background-color: #000;

    animation-name: fadeInModal;
    /* name of the animation */
    animation-duration: .5s;
    /* duration of the animation */
    animation-fill-mode: forwards;
    /* keep the end state after the animation */
    opacity: 0;
    /* make sure the element starts invisible */
}

.modal-content {
    opacity: 1;
    color: #E6E6E8;
    display: flex;
    margin: 0 20vw;
    flex-direction: column;
    align-items: center;
}

.modal-content ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}


@keyframes fallOut {
    0% {
        transform: translateY(-5vh);
        opacity: 0;
    }

    1% {
        transform: translateY(-5vh);
        opacity: 0;
    }

    100% {
        transform: translateY(0vh);
        opacity: 1;
    }
}

/* The element to apply the animation to */
.fall-out-3 {
    animation-name: fallOut;
    /* name of the animation */
    animation-duration: 0.5s;
    /* duration of the animation */
    /* animation-delay: .5s; */
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    /* keep the end state after the animation */
}

.fall-out-2 {
    animation-name: fallOut;
    /* name of the animation */
    animation-duration: 0.75s;
    /* duration of the animation */
    /* animation-delay: .8s; */
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    /* keep the end state after the animation */
}

.fall-out-1 {
    animation-name: fallOut;
    /* name of the animation */
    animation-duration: 1s;
    /* duration of the animation */
    /* animation-delay: 1s; */
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    /* keep the end state after the animation */
}

@keyframes fallIn {
    0% {
        transform: translateY(0vh);
        opacity: 1;
    }

    99% {
        transform: translateY(-5vh);
        opacity: 0;
    }

    100% {
        transform: translateY(-5vh);
        opacity: 0;
    }
}

.fall-in-1 {
    animation-name: fallIn;
    /* name of the animation */
    animation-duration: 1s;
    /* duration of the animation */
    animation-delay: .5s;
    animation-fill-mode: forwards;
    /* keep the end state after the animation */
}

.fall-in-2 {
    animation-name: fallIn;
    /* name of the animation */
    animation-duration: 0.75s;
    /* duration of the animation */
    animation-delay: .8s;
    animation-fill-mode: forwards;
    /* keep the end state after the animation */
}

.fall-in-3 {
    animation-name: fallIn;
    /* name of the animation */
    animation-duration: 0.5s;
    /* duration of the animation */
    animation-delay: 1s;
    animation-fill-mode: forwards;
    /* keep the end state after the animation */
}

.social-links-container {
    z-index: 1;
    color: #E6E6E8;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;

    margin-bottom: 12vh;
}

.social-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 0 20px;
}
.social-links .social-icon{
    width: 80px!important;
    height: 80px!important;
}
.social-links-container .copyright-text{
    font-family: 'Arial', sans-serif;
}
.modal-about .modal-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 30px;
    align-items: center;
    justify-content: space-between;
}
.modal-about .modal-content .video{
    width: 100%;
    aspect-ratio: 16/9;
}
.modal-about .modal-content .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px 0;
}
.modal-about .modal-content .text *{
    text-align: center;
    margin: 0;
}
.modal-about .modal-content .video iframe{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-contacts .modal-content{
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}
.modal-contacts .modal-content .mail{
    font-size: 2em;
    color: white;
    text-decoration: none;
    margin: 0;
    position: relative;
}
.modal-contacts .modal-content .mail:hover{
    /*text-decoration: underline;*/
}
.modal-contacts .modal-content ul{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px 0;
}
.modal-contacts .modal-content ul a{
    color: white;
    font-size: 1.2em;
    text-decoration: none;
}
.modal-contacts .modal-content ul a:before{
    height: 1px;
    bottom: -1px;
}
.modal-contacts .modal-content .social-links-container{
    margin: 0;
}
.modal-contacts .modal-content .social-links-container .copyright-text{
    display: none;
}

.modal-contacts .social-links-container{
    position: static;
    transform: none;
}

.modal-anim-link{
    position: relative;
    display: inline-block;
}
.modal-anim-link:before{
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 2px;
    transition: all .4s;
    background: white;
}
.modal-anim-link:hover:before{
    width: 100%;
}

.mob-screen{
    display: none;
}


@media screen and (max-width: 1024px) {
    .page-container {
        height: calc(var(--vh, 1vh) * 100);
    }

    .nav-item-top-right .nav-item-label{
        font-size: 1.5em;
    }
    .nav-item-faded .nav-item-label{
        font-size: 1.5em;
    }
    .nav-item-label{
        font-size: 1.5em;
    }

    .nav-item-bottom-left, .nav-item-bottom-right{
        margin-bottom: 8vh;
    }
    .nav-item-bottom-right{
        grid-column: 3/4;
    }
    .social-links-container{
        position: fixed;
        bottom: 8vh;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0;
    }

    .nav-item-top-right .nav-item-label{
        padding-left: 110px;
    }
    .nav-item-top-right .nav-item-label:before{
        width: 100px;
    }
    .nav-item-top-right .nav-subitems{
        opacity: 0!important;
    }
}


@media screen and (max-width: 768px) {
    .page-container{
        overflow: hidden;
    }
    .nav-item-top-right{
        margin: 30px 15px 0 0;
    }
    .nav-item-top-left{
        margin: 30px 0 0 15px;
    }
    .nav-item-bottom-left{
        margin: 0 0 42px 15px;
    }
    .nav-item-bottom-right{
        margin: 0 15px 42px 0px;
    }
    .social-links-container{
        bottom: 58px;
    }

    .social-links .social-icon{
        width: 50px!important;
        height: 50px!important;
    }

    footer .copyright-text{
        margin: 0;
    }

    /*body{*/
    /*    overflow: hidden;*/
    /*}*/
    /*.modal-header{}*/
    .modal-about .modal-container{

    }
    .modal-about .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px 0;
        height: 80vh;
        overflow: auto;
        margin: 0 45px;
    }
    .modal-about .modal-container{
        justify-content: flex-start;
        /*top: 100px;*/
        padding-top: 15vh;
    }
    .modal-about .modal-header{
        display: none;
    }
    .modal-about .modal-content .text *{
        font-size: 14px;
    }
    .center-logo{
        height: auto;
        width: 100vw;
    }

    /*.modal-about .modal-content .video{*/
    /*    display: none;*/
    /*}*/

    .mob-screen{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000102;
        z-index: 999999;
        align-items: center;
        justify-content: center;
        /*display: none;*/
    }

    .mob-screen video{
        width: 90%;
    }
}
