.App {}

.fadeIn {
  animation: 1s fadeIn forwards;
}

.fadeOut {
  animation: 1s fadeOut forwards;
}

body.hide-scroll{
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
